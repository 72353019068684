import React from "react";
import {  Link } from 'react-router-dom'
import './Login.css';
import logo from '../images/Naidus directory.png';
import { useState ,useEffect } from "react";
import {AiFillEye} from 'react-icons/ai'
 import axios from "axios";
 import { useHistory } from "react-router-dom";
 import { useForm } from "react-hook-form";
 import validator from "validator";
 import { toast } from "react-toastify";
 import {configData} from "../Config"




const ForgotPassword =()=>{
    let history = useHistory();
    

  

   const[email, setEmail]=useState("");
   localStorage.setItem('email',email);



   const handleEmail =(e)=>{
    const { name, value } = e.target;
     setEmail(e.target.value)
  }
  

  const [error, setError] = useState(false);
  const [emailEroor ,setemailEroor] = useState(false);
  const [errforNotExist ,seterrforNotExist] = useState(false);


 const submitFormData = (e) => {
     debugger

    e.preventDefault();

     
 if (validator.isEmpty(email)) {
  setError(true);
}
else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
setemailEroor(true);
}
    
else {
      debugger
      
      handleSubmit1();
}
    
   };

  const handleSubmit1 = async (e) => {
    
    console.log(email);

    const response =  await axios.post(`${configData.ApiUrl}/forgotPassword?emailId=${email}` )

    .then(result =>{
      console.log('forgotresult',result.data.data);
      debugger;
      if(result.data.code ==200){
        toast.success("Otp Sent!")

        history.push("/ForgotEnterCode");
        localStorage.setItem('email',email);

      }
      else{
        seterrforNotExist(true)
        //toast.warn("Invalid user");
      }
    })
    .catch(error =>{
      console.log(error)
    })
    
    

  };



    return(
        <div className="container-fluid  forgotcontainer">
            
  <div className="row margintop "></div>
            <div className="row margintop">
                <div className="col-lg-1"></div>
                <div className="col-lg-1 col-sm-12 col-md-4">           
                   <img src={logo} style={{width:"187px", height:"77px"}}s/>
                  </div>
                <div className="col-lg-6 col-md-7 col-sm-12">
                    {/* <span className="abccommuniti-text-main">Naidu Directory</span> */}
                </div>

            </div>
            <div className=''>
            <div className="row margintop ">
                <div className="col-lg-4"></div>
                <div className="col-lg-4 col-sm-12 col-md-12 log-form-div ">
                    <div className=" loginform-first-div">
                    <span className="signin-text" style={{marginLeft:"5px"}}>Forgot Password?</span>
                 </div>
                 <div className="row margintop3">
                    <span className="forgotpass-instruct-text">Don't worry, we will send you a message to help you reset your password</span>
                 </div>
                 <div className="row margintop">
                 <div className="col-lg-6 col-sm-12 col-md-6">

                 <label className="forgot-lbl">Registered Email Id
                </label>
                </div>
                 </div>
                 <div className="row margintop2">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 col-sm-12 col-md-12">
                        <input type="text" className=" form-control" id="email" 
                        onChange={handleEmail}
                     name="email"
                        placeholder="Enter your Registered Email Id "
                        style={{fontSize:"12px"}}
                     />
                        <div className='row '>
    <div className=' col-sm-12 col-md-12'></div>
    <div className=" col-lg-7 col-sm-12 col-md-12 ">
    <div>    
    {emailEroor  ? (
                    <span className='' style={{color:"red",fontSize:"12px"}}>Please Enter Valid Email. </span>) :
                     (""
              )}
    </div>
    <div>    
    {errforNotExist  ? (
                    <span className='' style={{color:"red",fontSize:"12px"}}>Email Id Does not Exist. </span>) :
                     (""
              )}
    </div>
                       {error && email == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Email is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
                    </div>

                 </div>
                 
                 <div className="row margintop">
                    <div className="col-lg-3"></div>
                    <div className="col-lg-4 col-md-10 col-sm-12 margintop3">
                        <button className="signinbutton"  onClick={submitFormData} >Continue</button>
                    </div>

                 </div>
                 <div className="row margintop3"></div>
                 <div className="row margintop3"></div>


                    
                    </div>
            </div>
            </div>
           
            
            
            
           


        </div>
    );
}
export default ForgotPassword