import './JoinNow.css';
import LastNameSpellPop from './LastNameSpellPop';
import React, { useState, useEffect } from "react";
import SubCast from './SubCastPop';
import SuccessPop from './SuccessPop';
import './LastNameSpellPop.css'
import {MdFileCopy} from 'react-icons/md'
import {FaShareAltSquare} from 'react-icons/fa'
import ReactTooltip from "react-tooltip";
import {FaRegComments,FaUserClock,FaFilter,FaInfoCircle,FaRegHandshake,FaEdit,FaWhatsapp,FaPhoneSquareAlt,} from 'react-icons/fa';
import { useForm } from "react-hook-form";
import validator from "validator";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import SuccessPopCast from './SuccessPopCast';
import csc from "country-state-city";
import Select from "react-select";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { event } from 'jquery';
import { Countrycode } from './Countrycode';
import OtpInput from 'react-otp-input';
import {AiOutlineClose} from 'react-icons/ai'
import {configData} from "../../Config"
import {AiFillEye} from 'react-icons/ai'


const BasicInfo=({ basicInfo, setBasicInfo, handleNext, handleBack,confirmPass,setConfirmPass,confirmPassErr,setconfirmPassErr,basicSaveInfo 
  ,selectedVal,setSelectedVal})=> {

  //for countrycode

  //for country dropdown
  const countries = csc.getAllCountries();
  //console.log(csc.getAllCountries())

  const updatedCountries = countries.map((country) => ({
      label: country.name,
      value: country.id,
      ...country
  }));


  const [isOpen, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  }
  const [isOpencast, setIsOpencast] = useState(false);
  const togglePopupcast = () => {
    setIsOpencast(!isOpencast);
  }
  const [contactValidEroor ,setContactValidEroor] = useState(false);
  const [passwordEroor ,setpasswordEroor] = useState(false);
  const [emailEroor ,setemailEroor] = useState(false);
  const [FirstNameEroor ,setFirstNameEroor] = useState(false);
  const [MiddleNameEroor ,setMiddleNameEroor] = useState(false);

  
  const [phonevalue, setPhoneValue] = useState()


  //for  add basic info
  const submitBasicFormData = (e) => {
    debugger
    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
    if (validator.isEmpty(basicInfo.firstName)) {
        setError(true);
    }
    else if (!/^[a-zA-Z]*$/g.test(basicInfo.firstName)) {
      setFirstNameEroor(true);
    }
    else if (validator.isEmpty(basicInfo.emailId)) {
        setError(true);
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(basicInfo.emailId)) {
      setemailEroor(true);
    }
    else if (validator.isEmpty(basicInfo.middleName)) {
      setError(true);
    }
    else if (!/^[a-zA-Z]*$/g.test(basicInfo.middleName)) {
      setMiddleNameEroor(true);
    }
    else if (validator.isEmpty(basicInfo.lastName)) {
        setError(true);

    }
    else if (validator.isEmpty(basicInfo.nationality)) {
      setError(true);

  }
    else if (validator.isEmpty(basicInfo.religion)) {
      setError(true);

  }
  else if (validator.isEmpty(basicInfo.subCaste)) {
    setError(true);

}
else if (validator.isEmpty(basicInfo.otherCaste)&& basicInfo.subCaste=='Others') {
    setError(true);

 }

else if (validator.isEmpty(basicInfo.countryCode)) {
  setError(true);

}
else if (validator.isEmpty(basicInfo.mobileNumber)) {
  setError(true);

}
else if (basicInfo.mobileNumber.length != 10) {
  setError(true);

}
else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(basicInfo.mobileNumber)){
  setContactValidEroor(true);
  
}
else if (validator.isEmpty(basicInfo.password)) {
  setError(true);

}
else if(!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{6,15}$/.test(basicInfo.password)){
  setpasswordEroor(true);
  
}

 else if (basicInfo.password != confirmPass.confirmPassword) {
   setconfirmPassErr(true);

 }
 else if (basicInfo.password != confirmPass.confirmPassword) {
   setconfirmPassErr(true);

 }
 else if ( confirmPass.confirmPassword =="") {
  setError(true);

}
    
    else {
      
      //handleNext();
      basicSaveInfo();
    }
};

console.log('countrycode',basicInfo.countryCode)
  

let history = useHistory();


const redirectoLogin = () => {
  history.push("/Login")  };
  //for sub cast pop
  const [subCastPop ,setsubCastPop] = useState(false);
function subCastpoupopen (isSubCast){
  if(isSubCast){
    debugger
    setBasicInfo(preState =>({
      ...preState,
      "emailId":""
    }))
    setBasicInfo(preState =>({
      ...preState,
      "countryCode":""
    }))
    setBasicInfo(preState =>({
      ...preState,
      "mobileNumber":""
    }))
    setBasicInfo(preState =>({
      ...preState,
      "spelling":""
    }))
    setsubCastPop(true);
  }
  else{
    setsubCastPop(false);
  }
}

const [isOpendivCast, setIsOpendivCast] = useState(true);
  const [isOtpDivCast, setIsotpDivCast] = useState(false);

  const onNextCast = (isbackCast) => {
   debugger
    if(isbackCast){

      setIsOpendivCast(true);
      setIsotpDivCast(false);
    }
    else{

      if (validator.isEmpty(subCast.spelling)) {
        setErrorSubCaste(true);
    }
     else if (!/^[a-zA-Z]*$/g.test(subCast.spelling)) {
      setMiddleNameEroor(true);
    }
    else if (validator.isEmpty(subCast.emailId)) {
      setErrorSubCaste(true);
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(subCast.emailId)) {
      setErrorSubCaste(true);
    }
    else if (validator.isEmpty(subCast.mobileNumber)) {
      setErrorSubCaste(true);

    }
    else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(subCast.mobileNumber)){
      setContactValidEroor(true);
      
    }
    else if (subCast.mobileNumber.length != 10) {
      setErrorSubCaste(true);
    
    }
    else if (validator.isEmpty(subCast.countryCode)) {
      setErrorSubCaste(true);

    }
    
    
    else {
      debugger
      setIsOpendivCast(false);
      setIsotpDivCast(true);
      sendOtpCast();   
     }

      
    }
    return
  }

  const [subCast,setsubCast]= useState({
    id:"",
    spelling:"",
    countryCode:"",
    mobileNumber:"",
    emailId:""

   })

   const submitFormDataCast = (e) => {
    debugger
    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
    if (validator.isEmpty(subCast.spelling)) {
      setErrorSubCaste(true);
    }
     else if (!/^[a-zA-Z]*$/g.test(subCast.spelling)) {
      setMiddleNameEroor(true);
    }
    else if (validator.isEmpty(subCast.emailId)) {
      setErrorSubCaste(true);
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(subCast.emailId)) {
      setErrorSubCaste(true);
    }
    else if (validator.isEmpty(subCast.mobileNumber)) {
      setErrorSubCaste(true);

    }
    else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(subCast.mobileNumber)){
      setContactValidEroor(true);
      
    }
    else if (subCast.mobileNumber.length != 10) {
      setErrorSubCaste(true);
    
    }
    else if (validator.isEmpty(subCast.countryCode)) {
      setErrorSubCaste(true);

    }
    
    
    else {
      debugger
      if(isOtpDivCast){
      sendOtpCast();}
    }
};

const sendOtpCast = async()=>{
  if(isOpendivCast){
  
  const result = await axios.get(configData.ApiUrl+'/user/sendUserOTP?mobileNo='+
  subCast.mobileNumber+ '&emailId='+subCast.emailId+'&countryCode='+subCast.countryCode);
  console.log("sendotpcast",result.data.data)
    if(result.data.code==200){
      toast.success("Otp sent!")

      setIsotpDivCast(true);
      setIsOpendivCast(false);
  
    }
    else if(result.data.code==400){
      toast.warn("Mobile no Already Exist!");
      setIsotpDivCast(false);
      setIsOpendivCast(true);

     }
     else if(result.data.code==409){
      toast.warn("Email Already Exist!");
      setIsotpDivCast(false);
      setIsOpendivCast(true);

     }
    else{
      toast.warn("Otp sent fail!")

      setIsotpDivCast(false);
      setIsOpendivCast(true);
  
  
    }
  }
  
 }


 //for subcast resend otp
 const resendOtpSubcast =async()=>{
  debugger;

  const result = await axios.get(configData.ApiUrl+'/user/resendUserOTP?mobileNo='+
  subCast.mobileNumber+ '&emailId='+subCast.emailId +'&countryCode='+subCast.countryCode);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Sent!");

  }
  else{
    toast.warn("Otp Sent fail")

  }


}

//validatin for subcast
const validateSubcasteOtp = async(e) =>{
  e.preventDefault();
if(otpNew1=='' || otpNew1.length<4){
  setErrorSubCaste(true);
}
else{
  handleVerifyOptpCast();
}
}

 const handleVerifyOptpCast =async()=>{
  debugger;
  if(subCast.countryCode=="+91"){

  // const result = await axios.get('/user/validateMobileOtp?mobileNo='+
  // subCast.mobileNumber+ '&otp='+otp.otpNum1+otp.otpNum2+otp.otpNum3+otp.otpNum4);
  const result = await axios.get(configData.ApiUrl+'/user/validateMobileOtp?mobileNo='+
   subCast.mobileNumber+ '&otp='+otpNew1);
  
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
    addNewCast()

  }
  else{
    toast.warn("Otp Does not match!");

  }
}
else{

  const result = await axios.get(configData.ApiUrl+'/user/validateEmailOtp?emailId='+
  subCast.emailId+ '&otp='+otpNew1);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
    //togglePopup()
    addNewCast()
  }
  else{
    toast.warn("Otp Does not match!");

  }
}

}

const addNewCast =(e)=>{
  const response = axios.post(configData.ApiUrl+'/user/addNewSubCaste', subCast)
    response.then(() => {
       toast.success("sub Cast  has been added Successfully!");
        togglePopup()
        subCastpoupopen(false)
        setIsOpendivCast(true)
        setIsotpDivCast(false)
    })
    response.catch((err) => {
      alert("Getting error in featching data")
    });
    console.log("response", response?.data)
}

  //for last name pop
  const [lastnamePop ,setlastnamePop] = useState(false);
function lastNamepoupopen (isLastname){
  if(isLastname){
    debugger
    setLastname(preState =>({
      ...preState,
      "emailId":""
    }))
    setLastname(preState =>({
      ...preState,
      "countryCode":""
    }))
    setLastname(preState =>({
      ...preState,
      "mobileNumber":""
    }))
    setLastname(preState =>({
      ...preState,
      "spelling":""
    }))
    setlastnamePop(true);
  }
  else{
    setlastnamePop(false);
  }
}



const [isOpendiv, setIsOpendiv] = useState(true);
  const [isOtpDiv, setIsotpDiv] = useState(false);

  const onNext = (isback) => {
   debugger
    if(isback){

      setIsOpendiv(true);
      setIsotpDiv(false);
    }
    else{
      
      
      //submitFormData();

      const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

   // e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
    if (validator.isEmpty(lastname.spelling)) {
      setErrorLastName(true);
    }
    else if (!/^[a-zA-Z]*$/g.test(lastname.spelling)) {
      setMiddleNameEroor(true);
    }
    else if (validator.isEmpty(lastname.emailId)) {
      setErrorLastName(true);
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(lastname.emailId)) {
      setemailEroor(true);
    }
    else if (validator.isEmpty(lastname.mobileNumber)) {
      setErrorLastName(true);

    }
    else if (lastname.mobileNumber.length != 10) {
      setErrorLastName(true);
    
    }
    else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(lastname.mobileNumber)){
      setContactValidEroor(true);
      
    }
    else if (validator.isEmpty(lastname.countryCode)) {
      setErrorLastName(true);

    }
    else {
      debugger
      setIsOpendiv(false);
      setIsotpDiv(true);
      
      sendOtp();
    
    }
      //sendOtp();
    }
    return
  }

  const [enablejoin, setenableResend] = useState(false);
  const [enablejoinSubcaste, setenableResendSubCaste] = useState(false);


  const counterandbtn =()=>{
    setCounter(30);
    setenableResend(false);
   resendOtp() ;
  }
  const counterandbtnSubcast =()=>{
    setCounterSubCaste(30);
    setenableResendSubCaste(false);
   resendOtpSubcast();    
  }
  
      const [counter, setCounter]= useState(46)
      const [counterSubCaste, setCounterSubCaste]= useState(46)


    useEffect(() =>{
      if(counter === 0){
        setenableResend(true);

    
      }
      
      const timer =
      counter > 0 && setInterval(() =>setCounter(counter-1), 1000);
      return () => clearInterval(timer);
    },[counter]);

    
    
    useEffect(() =>{
      if(counterSubCaste === 0){
        setenableResendSubCaste(true);

    
      }
      
      const timer1 =
      counterSubCaste > 0 && setInterval(() =>setCounterSubCaste(counterSubCaste-1), 1000);
      return () => clearInterval(timer1);
    },[counterSubCaste]);

   const [lastname,setLastname]= useState({
    id:"",
    spelling:"",
    countryCode:"",
    mobileNumber:"",
    emailId:""

   })

  const mobileNumberFormate = lastname.mobileNumber.substr(0, 2) + 'xxxxxx' 
   + lastname.mobileNumber.substr(8, 9);
   
   //for sucast mobile
   const mobileNumberFormate1 = subCast.mobileNumber.substr(0, 2) + 'xxxxxx' 
   + subCast.mobileNumber.substr(8, 9);


   //for lastname emeil
   let email = lastname.emailId
   let chunks = email.split("@");
   let Email = `${chunks[0].slice(0, 3)}xxxxxx${chunks[0].substr(chunks[0].length - 3)}@${chunks[1]}`

   //for subcast
   let emailsubcast = subCast.emailId
   let chunkssubcast = emailsubcast.split("@");
   let Emailsubcast = `${chunkssubcast[0].slice(0, 3)}xxxxxx${chunkssubcast[0].substr(chunkssubcast[0].length - 3)}@${chunkssubcast[1]}`
   
   const onchangecountry=(e)=>{
    debugger
    const {name,value,type,checked}=e.currentTarget;
    debugger;
   }

   const handleChange=(e)=>{
    const {name,value,type,checked}=e.currentTarget;
    
    setLastname(preState =>({
      ...preState,
      [name]:type ==='checkbox'?checked: value
    }))
    setsubCast(preState =>({
      ...preState,
      [name]:type ==='checkbox'?checked: value
    }))
    
    setBasicInfo(preState =>({
      ...preState,
      [name]:type ==='checkbox'?checked: value
    }))
    
    setConfirmPass(preState =>({
      ...preState,
      [name]:type ==='checkbox'?checked: value
    }))
  
  }
  const [errorSubCaste, setErrorSubCaste] = useState(false);

   const [errorLastName, setErrorLastName] = useState(false);
   const [error, setError] = useState(false);

//for lastname
   const submitFormData = (e) => {
    debugger
    const regex = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i;

    e.preventDefault();

    // checking if value of first name and last name is empty show error else take to step 2
    if (validator.isEmpty(lastname.spelling)) {
      setErrorLastName(true);
    }
    else if (!/^[a-zA-Z]*$/g.test(lastname.spelling)) {
      setMiddleNameEroor(true);
    }
    else if (validator.isEmpty(lastname.emailId)) {
      setErrorLastName(true);
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(lastname.emailId)) {
      setemailEroor(true);
    }
    else if (validator.isEmpty(lastname.mobileNumber)) {
      setErrorLastName(true);

    }
    else if (lastname.mobileNumber.length != 10) {
      setErrorLastName(true);
    
    }
    else if(!/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(lastname.mobileNumber)){
      setContactValidEroor(true);
      
    }
    else if (validator.isEmpty(lastname.countryCode)) {
      setErrorLastName(true);

    }
    else {
      debugger
      if(isOtpDiv)
      {
      sendOtp();
    }
    }
};


const sendOtp = async()=>{
if(isOpendiv){

const result = await axios.get(configData.ApiUrl+'/user/sendUserOTP?mobileNo='+
  lastname.mobileNumber+ '&emailId='+lastname.emailId+'&countryCode='+lastname.countryCode);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp sent!")
    setIsotpDiv(true);
    setIsOpendiv(false);

  }
  else if(result.data.code==400){
    toast.warn("Mobile no Already Exist!");
    setIsotpDiv(false);
    setIsOpendiv(true);
   }
   else if(result.data.code==409){
    toast.warn("Email Already Exist!");
    setIsotpDiv(false);
    setIsOpendiv(true);
   }
  else{
    toast.warn("Otp Does not sent!")

    setIsotpDiv(false);
    setIsOpendiv(true);


  }
}
// if(lastname.countryCode=="+91"){
//   const result = await axios.get("/user/validateMobileOtp");
// }
// else{
//   const result = await axios.get("/user/validateEmailOtp");
// }


}

const [otp,setOtp]= useState({
  otpNum1:"",
  otpNum2:"",
  otpNum3:"",
  otpNum4:""

 })

 const [otpNew, setOtpNew] = useState('');
 const [otpNew1, setOtpNew1] = useState('');




 const submitOtpFormData = (e) => {
   debugger
   e.preventDefault();

   // checking if value of first name and last name is empty show error else take to step 2
   if (otp.otpNum1==""||otp.otpNum1==null) {
       setError(true);
   }
   else if (otp.otpNum2==""||otp.otpNum2==null) {
       setError(true);
   }
   
   else if (otp.otpNum3==""||otp.otpNum3==null) {
     setError(true);

   }
   else if (otp.otpNum4==""||otp.otpNum4==null) {
     setError(true);

   }
   
else {
     debugger
     handleVerifyOptp();
   }
};

const submitOtpCastFormData = (e) => {
  debugger
  e.preventDefault();

  // checking if value of first name and last name is empty show error else take to step 2
  if (otp.otpNum1==""||otp.otpNum1==null) {
      setError(true);
  }
  else if (otp.otpNum2==""||otp.otpNum2==null) {
      setError(true);
  }
  
  else if (otp.otpNum3==""||otp.otpNum3==null) {
    setError(true);

  }
  else if (otp.otpNum4==""||otp.otpNum4==null) {
    setError(true);

  }
  
else {
    debugger
    handleVerifyOptpCast();
  }
};


 const handlechangeOtp =(e)=>{
  const {name,value,type,checked}=e.currentTarget;
  setOtp(preState =>({
    ...preState,
    [name]:type ==='checkbox'?checked: value
  }))
}
//validatin for lastnme
const validateLastNameOtp = async(e) =>{
  e.preventDefault();
if(otpNew=='' || otpNew.length<4){
  setErrorLastName(true);
}
else{
  handleVerifyOptp();
}
}

const handleVerifyOptp =async()=>{
  debugger;
  if(lastname.countryCode=="+91"){

  // const result = await axios.get('/user/validateMobileOtp?mobileNo='+
  // lastname.mobileNumber+ '&otp='+otp.otpNum1+otp.otpNum2+otp.otpNum3+otp.otpNum4);
  const result = await axios.get(configData.ApiUrl+'/user/validateMobileOtp?mobileNo='+
   lastname.mobileNumber+ '&otp='+otpNew);
  
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
    addNewName()

  }
  else{
    toast.warn("Otp Does not match!");

  }
}
else{

  const result = await axios.get(configData.ApiUrl+'/user/validateEmailOtp?emailId='+
  lastname.emailId+ '&otp='+otpNew);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Verified!");
    //togglePopup()
    addNewName()
  }
  else{
    toast.warn("Otp Does not match!");

  }
}

}

const addNewName =(e)=>{
  const response = axios.post(configData.ApiUrl+'/user/addNewLastName', lastname)
    response.then(() => {
       toast.success("Last Name  has been added Successfully!");
        togglePopup()
        lastNamepoupopen(false);
        setIsOpendiv(true)
        setIsotpDiv(false)

    })
    response.catch((err) => {
      alert("Getting error in featching data")
    });
    console.log("response", response?.data)
}

//resend otp
const resendOtp =async()=>{
  debugger;

  const result = await axios.get(configData.ApiUrl+'/user/resendUserOTP?mobileNo='+
  lastname.mobileNumber+ '&emailId='+lastname.emailId +'&countryCode='+lastname.countryCode);
console.log("sendotp",result.data.data)
  if(result.data.code==200){
    toast.success("Otp Sent!");

  }
  else{
    toast.warn("Otp Sent fail")

  }


}
    
//for lastname list
const [lastNameData, setlastNameData] = useState([]);

    useEffect(() => {
      loadLastName();
    }, []);
  
    const loadLastName = async () => {
      debugger
      const result = await axios.get(configData.ApiUrl+"/user/listOfLastNames");
      setlastNameData(result.data.data);
      console.log("lastnme",result.data.data)
    };
//for subcast
const [subCastData, setsubCastData] = useState([]);

    useEffect(() => {
      loadsubCast();
    }, []);
  
    const loadsubCast = async () => {
      const result = await axios.get(configData.ApiUrl+"/user/listOfSubCastes");
      setsubCastData(result.data.data);
      console.log("subCast",result.data.data)
    };
    
    const ddlChange =(e)=>{

      //setFormData({...formData, reqcountry:e.value,reqcountryid:e.value});
      debugger;
      setBasicInfo({ ...basicInfo, nationality: e.name, countryId: e.id ,nationalityCountryCode: e.id,})

      //setFormData({ ...formData, country: e.label, countryId: e.value,cval:e.value })
      setSelectedVal({label:e.label,id:e.value})
      console.log("sel val = ",selectedVal)
    }

    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmpasswordShown, setConfirmpasswordShown] = useState(false);


   const togglePasswordVisiblity = () => {
     setPasswordShown(passwordShown ? false : true);
   };
   const confirmtogglePasswordVisiblity = () => {
    setConfirmpasswordShown(confirmpasswordShown ? false : true);
  };
return (
        <div className="container">
          <form onSubmit={submitBasicFormData}>
          <div className="row mt-1">
<div className="col-lg-9 col-sm-12 col-md-12">
<div className="margintop row">
  <div className="col-lg-3 col-sm-12 col-md-12 textright txtleftformobres" style={{marginTop:"6px"}}>
  <span for="inputState" className="lbl " style={{fontSize:'12px',textTransform:"capitalize"}}>Name<span className="asteric"></span></span>
  </div>
  <div className="col-lg-3 col-sm-12 col-md-12  textleft form-group">
  <input type="text" className="textbox form-control" id="fst"
         name="firstName"
         placeholder=" First name"
         onChange={handleChange}
         value={basicInfo.firstName}
         style={{fontSize:'12px',textTransform:"capitalize"}}    />
  </div>
  <div className="col-lg-3 col-sm-12 col-md-12 textleft form-group">
  <input type="text" className="textbox form-control" id="fst"
         name="middleName"
         onChange={handleChange}
         value={basicInfo.middleName}

         placeholder="Middle Name"
         style={{fontSize:'12px',textTransform:"capitalize"}}    />
    </div>
    <div className="col-lg-3 col-sm-12 col-md-12 textleft form-group">
    <select   id="inputState" name='lastName'   
           onChange={handleChange}
           value={basicInfo?.lastName}
           className="form-control textboxselect" style={{fontSize:'12px',textTransform:"capitalize"}}>
                          <option selected value=""> Select </option>

            {
                  
                  lastNameData.map((result) =>(
                  <option value={result}>{result}</option>
                  ))

                }
               
            </select>
    </div>

   
    </div>
    <div className='row margintpmins1'>
    <div className='col-lg-3'></div>
    <div className="col-lg-3 col-sm-12 col-md-12 ">
      <span className={FirstNameEroor?"":'hide'}style={{ color: "red",fontSize:"12px" }} >FirstName contains only charecters</span>
                                    {error && basicInfo.firstName == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            First Name is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                <div className="col-lg-3 col-sm-12 col-md-12" style={{whiteSpace:""}}>
                                <span style={{ color: "red",fontSize:"12px" }} className={MiddleNameEroor?"":'hide'}>Middle Name contains only charecters</span>

                                    {error && basicInfo.middleName == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Middle Name is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="col-lg-3 col-sm-12 col-md-12 ">
                                    {error && basicInfo.lastName == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Last Name is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
    
  </div>
</div>
<div className="col-lg-3 col-md-12 col-sm-12 margintop" style={{marginTop:"33px"}}>
<span className="question lastname-textpop" onClick={() => lastNamepoupopen(true)}>Didn't find your last name spelling?</span>
</div>

            </div>

            <div className="row margintop">
<div className="col-lg-9 col-sm-12 col-md-12">
<div className=" row">
  <div className="col-lg-3 col-sm-12 col-md-12 textright txtleftformobres" style={{marginTop:"6px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Nationality<span className="asteric"></span></span>
  </div>
 
    <div className="col-lg-9 col-sm-12 col-md-12 textleft form-group">
    <Select
              style={{fontSize:"11px"}}
                                    id="country"
                                    name="nationality"
                                    label="country"
                                    className="countrydrp "
                                    rules={{ required: true }}
                                    options={updatedCountries}
                                    onChange={ddlChange}
                                    value={selectedVal}
                                    // onChange={(event) =>
                                    //   setBasicInfo({ ...basicInfo, nationality: event.name, countryId: event.id ,nationalityCountryCode: event.id})
                                    // }


                                />
    </div>
   
    </div>
</div>
<div className="col-lg-3 col-sm-12 col-md-12 margintop">
</div>
            </div>
            <div className='row margintpmins1'>
    <div className='col-lg-2 col-sm-12 col-md-12'></div>
    <div className=" col-lg-3 col-md-3 col-sm-12 textcenter">
                                    {error && basicInfo.nationality == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Nationality is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
           
            <div className="row margintop">
<div className="col-lg-9 col-sm-12 col-md-12">
<div className=" row">
  <div className="col-3 textright txtleftformobres" style={{marginTop:"5px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Religion<span className="asteric"></span></span>
  </div>
 
    <div className="col-lg-9 col-sm-12 com-md-12 textleft form-group">
    <select   id="inputState" name='religion' onChange={handleChange} value={basicInfo?.religion}
            className="form-control textboxselect" style={{fontSize:'12px',textTransform:"capitalize"}} >
            
            
              <option selected value=""> Select Religion</option>
               <option value="Hindu">Hindu</option>
            
    </select>
    </div>
   
    </div>
</div>
<div className="col-lg-3 col-md-12 col-sm-12 margintop">
</div>
            </div>
            <div className='row margintpmins1'>
    <div className='col-lg-2 col-md-12 col-sm-12'></div>
    <div className="col-lg-3 col-md-12 col-sm-12 textcenter">
                                    {error && basicInfo.religion == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Religion is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
            
            <div className="row margintop">
<div className="col-lg-9 col-sm-12 col-md-12">
<div className=" row">
  <div className="col-lg-3 col-sm-12 col-md-12 textright txtleftformobres" style={{marginTop:"5px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'13px'}}> Sub Caste<span className="asteric"></span></span>
  </div>
  
 
    <div className="col-lg-9 col-md-12 col-sm-12 textleft form-group">
    <select   id="inputState" name='subCaste' onChange={handleChange} value={basicInfo.subCaste}
            className="form-control textboxselect" style={{fontSize:'12px',textTransform:"capitalize"}} >
           
           <option selected value=""> Select </option>

               {
                  
                  subCastData.map((result) =>(
                  <option value={result}>{result}</option>
                  ) )

                }
            </select>
            <div className={basicInfo.subCaste=="Others"?'margintop':"hide"}>
              {
    basicInfo.subCaste=="Others" && (
      <input type="text" className=" form-control" id="fst"
         name="otherCaste"
         placeholder="Sub Caste"
         onChange={handleChange}
         value={basicInfo.otherCaste}
         style={{fontSize:'12px',textTransform:"capitalize"}}    />
    )
    }
            </div>
    </div>
   
    </div>
</div>
<div className="col-lg-3 col-md-12 col-sm-12 " style={{marginTop:"7px"}}>
{/* <span className="question lastname-textpop" onClick={()=>subCastpoupopen(true)}>Didn't find your Sub Caste?</span> */}

</div>
{isOpencast && <SubCast
      
      handleClose={togglePopupcast}
    />}
            </div>
            <div className='row margintpmins1'>
    <div className='col-lg-2 col-md-12 col-sm-12'></div>
    <div className="col-lg-3 col-sm-12 col-md-12 textcenter">
                                    {error && basicInfo.subCaste == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Sub Caste  is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    <div>
                                    {error && basicInfo.otherCaste=="" && basicInfo.subCaste=='Others' ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Other Subcaste is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    </div>
                                </div>
                                
                                
  </div>
            <div className="row margintop">
<div className="col-lg-9 col-sm-12 col-md-12">
<div className=" row">
  <div className="col-lg-3 col-sm-12 col-md-12 textright txtleftformobres" style={{marginTop:"5px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize"}}>Email Id<span className="asteric"></span></span>
  </div>
 
    <div className="col-lg-9 col-md-12 col-sm-12 textleft form-group">
    <input type="text" className=" form-control" id="fst"
         name="emailId"
         onChange={handleChange}
         placeholder="Enter Email Id"
         style={{fontSize:'12px',height:"37px"}}
         value={basicInfo.emailId}
    />
    </div>
   
    </div>
</div>
<div className="col-lg-3 col-sm-12 col-md-12 ">

</div>
            </div>
            <div className='row margintpmins1'>
    <div className='col-lg-2 col-sm-12 col-md-12'></div>
    <div className=" col-lg-3 col-sm-12 col-md-12 textcenter">
    <span className={emailEroor?'':"hide"} style={{color:"red",fontSize:"12px"}}>Please Enter Valid Email </span>

                                    {error && basicInfo.emailId == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Email is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
            <div className="row margintop">
<div className="col-lg-9 col-sm-12 col-md-12">
<div className=" row">
  <div className="col-lg-3 col-sm-12 col-md-12 textright txtleftformobres" style={{marginTop:"6px"}}>
  <span for="inputState" className="lbl " style={{fontSize:'13px'}}>Mobile No. <span className="asteric"></span></span>
  </div>
 <div className='col-lg-3 col-sm-2 col-md-2'>
 {/* <input type="text" className=" form-control" id="fst"
         name="countryCode"
         placeholder="CC"
         onChange={handleChange}

         style={{fontSize:'12px',textTransform:"capitalize"}}    /> */}
         {/* <PhoneInput
      placeholder="Enter phone number"
      value={phonevalue}
      defaultCountry='IN'
      onChange={setPhoneValue}
      limitMaxLength="6"/> */}
      <select   id="inputState"  
                className=" form-control "
                name='countryCode'
                style={{height:"37px"}}
                onChange={handleChange}
                value={basicInfo.countryCode}
                >
                   <option selected value=""> Select </option> 

              {
                   Countrycode.map((result) =>(
                  <option  value={result.dial_code}>{result.name} ({result.dial_code})</option>
                  ) )

                }
                </select> 
      
 </div>
    <div className="col-lg-6 col-sm-10 col-md-1o textleft form-group">
    <input type="number" className=" form-control" id="fst"
         name="mobileNumber"
         onChange={handleChange}
         placeholder="Enter Mobile No."
         style={{fontSize:'12px',textTransform:"capitalize",height:"37px"}}
         value={basicInfo.mobileNumber}  />
    </div>
   
    </div>
</div>
<div className="col-lg-3 ">

</div>
            </div>
            <div className='row margintpmins1'>
    <div className='col-lg-2 col-sm-12 col-md-12'></div>
    <div className="col-lg-2 col-sm-12 col-md-12 textright">
                                    {error && basicInfo.countryCode == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            CC is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className=" col-lg-5 col-sm-12 col-md-12 " style={{marginLeft:"40px"}}>
                                <span className={contactValidEroor?'':"hide"} style={{color:"red",fontSize:"12px" ,marginLeft:'0px'}}>Please Enter Valid Mobile No</span>

                                    {error && basicInfo.mobileNumber == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            MobileNo is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {error &&  basicInfo.mobileNumber.length !=10 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Mobile No is 10 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
  </div>
            <div className="row margintop">
<div className="col-lg-9 col-md-12 col-sm-12">
<div className=" row">
  <div className="col-lg-3 col-sm-12 col-md-12 textright txtleftformobres" style={{marginTop:"4px"}}>
  <span for="inputState" className="lbl " style={{fontSize:'13px'}}> Password<span className="asteric"></span></span>
  </div>
 
    <div className="col-lg-9 col-sm-12 col-md-12 textleft form-group">
    <input 
    //type="password"
     className=" form-control" id="fst"
         name="password"
         onChange={handleChange}
          placeholder="Type Password"
         style={{fontSize:'12px',height:"37px"}}
         value={basicInfo.password}
         type={passwordShown ? "text" : "password"}
    />
     { (basicInfo.password !== "") && <AiFillEye  className='eyeicon1' size='1.2em' onClick={togglePasswordVisiblity}/> }

    </div>
   
    </div>
</div>
<div className="col-lg-3 ">

</div>
            </div>
            <div className='row margintpmins1'>
    <div className='col-lg-2'></div>
    <div className=" col-lg-6  col-md-12 col-sm-12 textcenter">
    <span className={passwordEroor?'':"hide"} style={{color:"red",fontSize:"12px",marginLeft:"20px"}}> Password must be 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter</span>

                                </div>
                                
                                
                                
  </div>
  <div className='row '>
    <div className='col-lg-2'></div>
    <div className=" col-lg-3  col-md-12 col-sm-12 textcenter">

                                    {error && basicInfo.password == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Password is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                                
                                
  </div>
            
            <div className="row margintop">
<div className="col-lg-9 col-sm-12 col-md-12">
<div className=" row">
  <div className="col-lg-3 col-md-12 col-sm-12 textright txtleftformobres" style={{marginTop:"4px"}}>
  <span for="inputState" className="lbl  whitespacenowrap" style={{fontSize:'13px'}}>Confirm password<span className="asteric"></span></span>
  </div>
 
    <div className="col-lg-9 col-md-12 col-sm-12 textleft form-group">
    <input 
    //type="password" 
    className=" form-control" id="fst"
         name="confirmPassword"
         onChange={handleChange}
         placeholder="Confirm Password"
         style={{fontSize:'12px',height:"37px"}}
         value={basicInfo?.confirmPassword}
         type={confirmpasswordShown ? "text" : "password"}
    />
     { (confirmPass.confirmPassword !== "") && <AiFillEye  className='eyeicon1' size='1.2em' onClick={confirmtogglePasswordVisiblity}/> }
    </div>
   
    </div>
</div>
<div className="col-lg-3 ">

</div>
  </div>
  <div className='row margintpmins1'>
    <div className='col-lg-2 col-sm-12 col-md-12'></div>
    <div className=" col-lg-3 col-sm-12 col-md-12 textcenter">
                                    
                <span className={confirmPassErr?'':"hide"} style={{ color: "red",fontSize:"12px",marginLeft:"10px" }}>
                      Passwords does not match.
                   </span>
                   {error && confirmPass.confirmPassword == "" ? (
                  <span style={{ color: "red",fontSize:"12px", marginLeft:"23px"}}>
                Confirm Password is required.
               </span>
  ) : (
      ""
  )}
                                    
                                </div>
                                
                                
                                
  </div>
           
          
            <div className="margintop1 row ">
            <div className="col-lg-3 col-md-12 col-md-12 marginleftmins4"></div>
            <div className="col-3 margintop textright">
            <button
               className="displynoneprev"
              
              onClick={handleBack}
            >
              Previous
            </button>
            </div>
          <div className="col-lg-2 col-md-12 col-sm-12 margintop ">
          <button
            className="nextbtn "
              variant="contained"
              color="primary"
               //onClick={handleNext}
              type="submit"
            >
              Next
            </button>
            
            
          </div>
          <div className="margintop1  row"></div>

          <div className="margintop textcenter row">
            <div className="col-lg-4"></div>
            <div className="margintop col-lg-4 col-md-12 col-sm-12">
            <span className="alreadyspn " style={{marginLeft:'-84px'}}>Already a registered member ?</span>
            <span className=" bluetextforlink marginleft5" onClick={redirectoLogin} style={{cursor:"pointer"}}>Sign In</span>
            </div>
           
            </div>

            
            
          </div>  
          </form>
           

            
            <div className={lastnamePop?"":"hide"}>
            <div className="popup-boxlastnamepop">
      <div className="boxlastnamepop">
        <form onSubmit={submitFormData}>
      <div className={isOpendiv?"":"hide"}>
        <div className="topnewsrowlastspellpop ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-7">
                <span className="lastpop-top-text">Name Not Found!</span>
            
                 </div>
                 <div className='col-1'>
                 <span className="lastpop-top-text" style={{cursor:"pointer"}} onClick={() => lastNamepoupopen(false)}> <AiOutlineClose size="1.3em"/></span>

                 </div>
            </div>
           
        
        </div>
        <div className="row margintop3">
            <div className="col-1"></div>
            <div className="col-11">
                <span className="lastnamepoptop-text">Please fill up the below details and we will revert at the earliest</span>
            </div>
        </div>

        <div className="margintop row">
  <div className="col-3 textright ">
  <span for="inputState" className="lbl " style={{fontSize:'12px',textTransform:"capitalize"}}>Spelling</span>
  </div>
  <div className="col-7 textleft form-group">
  <input type="text" className=" form-control" id="fst"
         name="spelling"
         placeholder="Required Last Name"
         style={{fontSize:'12px',textTransform:"capitalize",height:'36px'}}
         onChange={handleChange}
         value={lastname?.spelling}
    />
    <div className="margtop1 nowrapcss">
    <span style={{ color: "red",fontSize:"12px" }} className={MiddleNameEroor?"":'hide'}>Last Name contains only charecters</span>

                                    {errorLastName && lastname.spelling == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Lastname Spelling is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
  </div>
  
  
    
   
    </div>
    <div className="margintop1 row">
  <div className="col-3 textright " style={{marginTop:"5px"}}>
  <span for="inputState" className="lbl sourcesansfont" style={{fontSize:"14px"}}>Email Id </span>
  <FaInfoCircle size='0.8em' className="margintpmins1" data-tip data-for="registerTip"/>:
            <ReactTooltip id="registerTip" place="right"  class='tooltip1' effect="solid" width="300px">
                    We will Notify on This email id once required name is added
                 </ReactTooltip>
  </div>
  <div className="col-7 textleft form-group">
  <input type="text" className=" form-control" id="fst"
         name="emailId"
         placeholder="Your Email Id"
         onChange={handleChange}
        value={lastname.emailId}
         style={{fontSize:'12px',textTransform:"",height:'36px'}}
    />
    <div className="margtop1 nowrapcss">
    <span className={emailEroor?'':"hide"} style={{color:"red",fontSize:"12px"}}>Please Enter Valid Email </span>

                                    {errorLastName && lastname.emailId == "" ? (
                                        <span style={{ color: "red" ,fontSize:"12px"}}>
                                            Email is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
  </div>
  
  
    
   
    </div>
    <div className="margintop1 row">
  <div className="col-3 textright" style={{marginTop:"5px"}}>
  <span for="inputState" className="lbl sourcesansfont" style={{fontSize:"14px"}}>Mobile No </span>
  </div>
  <div className="col-3 textleft form-group">
  {/* <input type="text" className=" form-control" id="fst"
         name="countryCode"
         placeholder="CC"
         onChange={handleChange}

         style={{fontSize:'12px',textTransform:"capitalize",height:'32px'}}
    /> */}
    <select   id="inputState"  
                className=" form-control "
                name='countryCode'
                style={{height:"36px"}}
                value={lastname.countryCode}
                onChange={handleChange}>
                <option selected value="">CC</option>
                {
                  
                  Countrycode.map((result) =>(
                  <option value={result.dial_code}>{result.name} ({result.dial_code})</option>
                  ) )

                }
                </select>
    </div>

  <div className="col-4 textleft form-group">
  
  <input type="text" className=" form-control" id="fst"
         name="mobileNumber"
         placeholder="Mobile No"
         onChange={handleChange}
          value={lastname.mobileNumber}
         style={{fontSize:'12px',textTransform:"capitalize",height:'36px'}}
    />
    
  </div>
  
</div>
<div className='row'>
    <div className='col-3'></div>
    <div className='col-7'>
    <div className=" nowrapcss ">
    <span className={contactValidEroor?'':"hide"} style={{color:"red",fontSize:"12px"}}>Please Enter Valid Mobile No</span>

                                    {errorLastName && lastname.mobileNumber == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Mobile is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {errorLastName &&  lastname.mobileNumber.length !=10 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Mobile No is 10 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className=" nowrapcss">
                                    {errorLastName && lastname.countryCode == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            countryCode is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
    </div>
  </div>
    
    <div className='row margintop3'>
                    <div className='col-2'></div>
                    <div className='col-4'>
                        <button className='cancelbtn'  onClick={() => lastNamepoupopen(false)} style={{width:'120px',height:"42px",marginLeft:"20px"}}>Cancel</button>
                    </div>
                    <div className='col-4'>
                     <button className='createeventbtn' type='submit' style={{width:'120px',height:"42px"}} onClick={()=>onNext(false)}>Next</button> 
                  </div>
                    <div className='col-2'></div>

                  </div>
        </div>
        
        <div className={isOtpDiv?"":"hide"}>
        <div className="topnewsrowlastspellpop ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-7">
                <span className="lastpop-top-text ">Name Not Found!</span>
            
                 </div>
                 <div className='col-1'>
                 <span className="lastpop-top-text" onClick={() => lastNamepoupopen(false)}><AiOutlineClose size="1.3em"/></span>

                 </div>

            </div>
           </div>
<div className="row margintop1">
  <div className='col-1'></div>
          <div className="margintop col-11">
            <span className="enterotptxt" style={{fontSize:'13px'}}>Enter the OTP send to your mobile number {mobileNumberFormate}</span>
            <div className='margintop3' style={{whiteSpace:'nowrap',textAlign:"center"}}>
              <span className='nri-text' style={{fontSize:'13px'}}>
                NRIs check the OTP sent on your registered email id {Email}
              </span>
            </div>
            </div>
            </div>
            {/* <div className='row margintop'>
              <div className='col-2 marginleftmins'></div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox form-control" id="fst"
               name="otpNum1" onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum1 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox1 textcenter form-control" id="fst"
               name="otpNum2" style={{marginLeft:2}} onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum2 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px",marginLeft:"-5px" }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox3 textcenter form-control" id="fst"
               name="otpNum3" style={{marginLeft:4}} onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum3 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" ,marginLeft:"-0px" }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox4  form-control" id="fst"
               name="otpNum4" style={{marginLeft:6}} onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum4 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px",marginLeft:"0px"  }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
            
            </div> */}
<div className='row margintop3'>
  <div className='col-3'></div>
  <div className='col-8'>
  <OtpInput
      value={otpNew}
      onChange={setOtpNew}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} style={{width:"3rem",height:"3rem",textAlign:"center",borderColor:"EE7600"}} />}
    />
    {errorLastName ? (
                                        <span style={{ color: "red",fontSize:"12px",marginLeft:"5px"  }}>
                                            Please enter OTP.
                                        </span>
                                    ) : (
                                        ""
                                    )}
  </div>


</div>

            <div className=' row margintop '>
              <div className='col-2'></div>
              <div className=' col-9'>
              <span className='dindopt-txt'style={{fontSize:'13px'}}>Didn't receive OTP? <span className={enablejoin ?"resend-text resendtxt":'dindopt-txt dindopt-txt1'} onClick={()=>counterandbtn()} >Resend</span>  in <span className='resend-text'>{counter} seconds</span></span>

              </div>
            </div>
            <div className='row margintop'>
                    <div className='col-2'></div>
                    <div className='col-4'>
                        <button className='cancelbtn'  style={{width:'120px',height:"42px",marginLeft:"20px"}} onClick={() => onNext(true)}>Previous</button>
                    </div>
                    <div className='col-4'>
                    <button className='createeventbtn' style={{width:'120px',height:"42px",justifySelf:"right"}} onClick={validateLastNameOtp}>Submit</button>
                  </div>
                  <div className='col-2'></div>
                  {/* {isOpen && <SuccessPop
      
      handleClose={togglePopup}
    />} */}
                    <div className='col-1'></div>

                  </div>

        </div>
        </form>
</div>
          
        
        </div>
</div>

<div className={subCastPop?"":"hide"}>
            <div className="popup-boxlastnamepop">
      <div className="boxlastnamepop">
        <form onSubmit={submitFormDataCast}>
      <div className={isOpendivCast?"":"hide"}>
        <div className="topnewsrowlastspellpop ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-7">
                <span className="lastpop-top-text">Sub Cast Not Found!</span>
            
                 </div>
                 <div className='col-1'>
                 <span className="lastpop-top-text" style={{cursor:'pointer'}} onClick={() => subCastpoupopen(false)}><AiOutlineClose size="1.3em"/></span>

                 </div>
            </div>
           
        
        </div>
        <div className="row margintop3">
            <div className="col-1"></div>
            <div className="col-11">
                <span className="lastnamepoptop-text">Please fill up the below details and we will revert at the earliest</span>
            </div>
        </div>

        <div className="margintop row">
  <div className="col-3 textright " style={{marginTop:"5px"}}>
  <span for="inputState" className="lbl" style={{fontSize:'12px',textTransform:"capitalize",height:'36px'}}
>Sub Caste</span>
  </div>
  <div className="col-7 textleft form-group">
  <input type="text" className=" form-control" id="fst"
         name="spelling"
         value={subCast.spelling}
         placeholder="Required Sub Cast"
         style={{fontSize:"12px",height:'36px'}}
         onChange={handleChange}
    />
    <div className="margtop1 nowrapcss">
    <span style={{ color: "red",fontSize:"12px" }} className={MiddleNameEroor?"":'hide'}>Sub Caste contains only charecters</span>

                                    {errorSubCaste && subCast.spelling == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Sub Caste Spelling is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
  </div>
  
  
    
   
    </div>
    <div className="margintop1 row">
  <div className="col-3 textright "style={{marginTop:"5px"}}>
  <span for="inputState" className="lbl " style={{fontSize:'12px',textTransform:"capitalize",height:'32px'}}
>Email Id </span>
  <FaInfoCircle size='0.8em' className="margintpmins1" data-tip data-for="registerTip"/>:
            <ReactTooltip id="registerTip" place="right"  class='tooltip1' effect="solid" width="300px">
                    We will Notify on This email id once required name is added
                 </ReactTooltip>
  </div>
  <div className="col-7 textleft form-group">
  <input type="text" className=" form-control" id="fst"
         name="emailId"
         placeholder="Your Email Id"
         onChange={handleChange}
value={subCast.emailId}
         style={{fontSize:"12px",height:'36px'}}
    />
    <div className="margtop1 nowrapcss">
    <span className={emailEroor?'':"hide"} style={{color:"red",fontSize:"12px"}}>Please Enter Valid Email </span>

                                    {errorSubCaste && subCast.emailId == "" ? (
                                        <span style={{ color: "red" ,fontSize:"12px"}}>
                                            Email is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
  </div>
  
  
    
   
    </div>
    <div className="margintop1 row">
  <div className="col-3 textright" style={{marginTop:"5px"}}>
  <span for="inputState" className="lbl " style={{fontSize:"13px"}}>Mobile No </span>
  </div>
  <div className="col-3 textleft form-group">
  {/* <input type="text" className=" form-control" id="fst"
         name="countryCode"
         placeholder="CC"
         onChange={handleChange}

         style={{fontSize:'12px',textTransform:"capitalize",height:'32px'}}
    /> */}
    <select   id="inputState"  
                className=" form-control "
                name='countryCode'
                value={subCast.countryCode}
                style={{height:"36px"}}
                onChange={handleChange}>
                <option selected value="">CC</option>
                {
                  
                  Countrycode.map((result) =>(
                  <option value={result.dial_code}>{result.name} ({result.dial_code})</option>
                  ) )

                }
                </select>
    </div>

  <div className="col-4 textleft form-group">
  
  <input type="text" className=" form-control" id="fst"
         name="mobileNumber"
         placeholder="Mobile No"
         onChange={handleChange}
        value={subCast.mobileNumber}
         style={{fontSize:'12px',textTransform:"capitalize",height:'36px'}}
    />
    
  </div>
  
</div>
<div className='row'>
    <div className='col-3'></div>
    <div className='col-7'>
    <div className=" nowrapcss ">
    <span className={contactValidEroor?'':"hide"} style={{color:"red",fontSize:"12px"}}>Please Enter Valid Mobile No</span>

                                    {errorSubCaste && subCast.mobileNumber == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Mobile is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {errorSubCaste &&  subCast.mobileNumber.length !=10 ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                        Mobile No is 10 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className=" nowrapcss">
                                    {errorSubCaste && subCast.countryCode == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            countryCode is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
    </div>
  </div>
    
    <div className='row margintop3'>
                    <div className='col-2'></div>
                    <div className='col-4 'style={{justifyItems:"end" ,marginLeft:"5px"}}>
                        <button className='cancelbtn'  onClick={() => subCastpoupopen(false)} style={{width:'120px',height:"42px",marginLeft:"20px"}}>Cancel</button>
                    </div>
                    <div className='col-4' style={{justifyItems:"start"}}>
                     <button className='createeventbtn' type='submit' style={{width:'120px',height:"42px"}} onClick={() => onNextCast(false)}>Next</button> 
                  </div>
                     <div className='col-2'></div> 

                  </div>
        </div>
        
        <div className={isOtpDivCast?"":"hide"}>
        <div className="topnewsrowlastspellpop ">
            <div className="row">
                <div className="col-4"></div>
                <div className="col-7">
                <span className="lastpop-top-text ">Sub Cast Not Found!</span>
            
                 </div>
                 <div className='col-1'>
                 <span className="lastpop-top-text" style={{cursor:"pointer"}} onClick={() => subCastpoupopen(false)}><AiOutlineClose size="1.3em"/></span>

                 </div>
            </div>
           </div>
<div className="row margintop1">
  <div className='col-1'></div>
          <div className="margintop col-11">
            <span className="enterotptxt" style={{fontSize:'13px'}}>Enter the OTP send to your mobile number {mobileNumberFormate1}</span>
            <div className='margintop3' style={{whiteSpace:'nowrap',textAlign:"center"}}>
              <span className='nri-text' style={{fontSize:'13px'}}>
                NRIs check the OTP sent on your registered email id {Emailsubcast}
              </span>
            </div>
            </div>
            </div>
            {/* <div className='row margintop'>
              <div className='col-2 marginleftmins'></div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox form-control" id="fst"
               name="otpNum1" onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum1 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox1 textcenter form-control" id="fst"
               name="otpNum2" style={{marginLeft:2}} onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum2 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px",marginLeft:"-5px" }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox3 textcenter form-control" id="fst"
               name="otpNum3" style={{marginLeft:4}} onChange={handlechangeOtp}/>
               <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum3 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px" ,marginLeft:"0px" }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
              <div className='col-2 textcenter'>
              <input type="number" className="otpbox4  form-control" id="fst"
               name="otpNum4" style={{marginLeft:6}} onChange={handlechangeOtp}/>
                <div className='row '>
                       <div className="  ">

                                    {error && otp.otpNum4 == "" ? (
                                        <span style={{ color: "red",fontSize:"12px",marginLeft:"0px"  }}>
                                             required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                        
                    </div>
              </div>
            
            </div> */}
            <div className='row margintop3'>
  <div className='col-3'></div>
  <div className='col-8'>
  <OtpInput
      value={otpNew1}
      onChange={setOtpNew1}
      numInputs={4}
      renderSeparator={<span>-</span>}
      renderInput={(props) => <input {...props} style={{width:"3rem",height:"3rem",textAlign:"center",borderColor:"EE7600"}} />}
    />
    {errorSubCaste ? (
                                        <span style={{ color: "red",fontSize:"12px",marginLeft:"5px"  }}>
                                            Please enter OTP.
                                        </span>
                                    ) : (
                                        ""
                                    )}
  </div>


</div>
            <div className=' row margintop '>
              <div className='col-2'></div>
              <div className=' col-9'>
              <span className='dindopt-txt'style={{fontSize:'13px'}}>Didn't receive OTP? <span className={enablejoinSubcaste ?"resend-text resendtxt":'dindopt-txt dindopt-txt1 cursorNone'} onClick={()=>counterandbtnSubcast()} >Resend</span>  in <span className='resend-text'>{counterSubCaste} seconds</span></span>

              </div>
            </div>
            <div className='row margintop'>
                    <div className='col-2'></div>
                    <div className='col-4'>
                        <button className='cancelbtn'  style={{width:'120px',height:"42px",marginLeft:"20px"}} onClick={() => onNextCast(true)}>Previous</button>
                    </div>
                    <div className='col-4'>
                    <button className='createeventbtn' style={{width:'120px',height:"42px",justifySelf:"right"}} onClick={validateSubcasteOtp}>Submit</button>
                  </div>
                  <div className='col-2'></div>
                  {/* {isOpen && <SuccessPopCast
      
      handleClose={togglePopup}
    />} */}
                    <div className='col-1'></div>

                  </div>
        </div>
        </form>
</div>
          
        
        </div>
</div>      </div>
    );
  }
  export default BasicInfo;