import React from "react";
import {  Link } from 'react-router-dom'
import './Login.css';
import logo from '../images/Naidus directory.png';
import { useState ,useEffect } from "react";
import {AiFillEye} from 'react-icons/ai'
 import axios from "axios";
 import { useHistory } from "react-router-dom";
 import { useForm } from "react-hook-form";
 import OtpInput from 'react-otp-input';
 import validator from "validator";
import { toast } from "react-toastify";
import {configData} from "../Config"




const ForgotEntercode =()=>{
    let history = useHistory();
    

    const authlocalstoragedata = JSON.parse(localStorage.getItem('authdata'));
    console.log("authdata11",authlocalstoragedata)


    localStorage.email = localStorage.email;
    const Email = localStorage.getItem('email');

    
    const[otpData, setotpData]=useState();
    const [errorotp, setError] = useState(false);
   
  const handleOtp =(e)=>{
    debugger
    const { name, value,type,checked } = e.currentTarget;
    setotpData(value)

    if(value != '' && value.length==6){
         submitFormData(value);

     }
     else if (validator.isEmpty(value) ) {
      setError(true);
    }
    else if(value.length !=6)
    {
      setError(true);
    
    }

  }



 const submitFormData = (otpCode) => {

      debugger
  setError(false);
      
      //handleSubmit1(otpCode);
      const response =  axios.get(configData.ApiUrl+'/validateEmailOtp?emailId='+localStorage.email+'&otp='+otpCode )

      .then(result =>{
        console.log(result.data.data);
        debugger;
        if(result.data.code ==200){
          toast.success("Otp verified")
          history.push("/ForgotResetPassword");
  
        }
        else{
          //seterrforNotExist(true)
          toast.warn("Invalid Otp");
        }
      })
      .catch(error =>{
        console.log(error)
      })

    
   };

  


    return(
        <div className="container-fluid  forgotcontainer">
            
  <div className="row margintop "></div>
            <div className="row margintop">
                <div className="col-1"></div>
                <div className="col-1">           
                   <img src={logo} style={{width:"187px", height:"77px"}}/>
                  </div>
                <div className="col-6">
                    {/* <span className="abccommuniti-text-main">Naidu Directory</span> */}
                </div>

            </div>
            
            <div className=''>
            <div className="row margintop ">
                <div className="col-lg-4"></div>
                <div className="col-lg-4 col-sm-12 col-md-12 log-form-div ">
                    <div className=" loginform-first-div">
                    <span className="signin-text" style={{marginLeft:"5px"}}>Enter Code?</span>
                 </div>
                 <div className="row margintop3">
                    <span className="forgotpass-instruct-text">Last step! To secure your account, enter the code we just sent to {Email}</span>
                 </div>
                 <div className="row margintop">
                 <label className="forgot-lbl">Code
                </label>
                 </div>
                 <div className="row margintop2">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10 col-sm-12 col-md-12">
                        <input type="number" className=" form-control" id="" onChange={handleOtp}
                     name="otp"
                     />
                        <div className='row '>
    <div className=' col-sm-12 col-md-12'></div>
    <div className=" col-lg-8 col-sm-12 col-md-12 ">
    
        {errorotp ==true && otpData == "" ? (
        <span style={{ color: "red",fontSize:"12px" }}>
                                            otp is required.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                    {errorotp==true &&  otpData.length != 6? (
                                        <span style={{ color: "red",fontSize:"12px" }}>
                                            Otp is 6 digits only.
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                
                            </div>

                    </div>

                 </div>
                 
                 <div className="row margintop3"></div>
                 <div className="row margintop3"></div>
                 <div className="row margintop3"></div>
                 <div className="row margintop3"></div>

                 <div className="row margintop3"></div>
                 <div className="row margintop3"></div>


                    
                    </div>
            </div>
   
   </div>
            
            
            
           


        </div>
    );
}
export default ForgotEntercode