import React, { useState, useEffect ,useRef} from "react";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { columns ,data, heading, Rows } from "./Data1";
import { Link } from "react-router-dom";
import {AiOutlineFilePdf} from 'react-icons/ai'
import axios from "axios";
import { Breadcrumb, Button, Card, Col, Form, FormGroup, Dropdown, ButtonGroup, InputGroup, Row, Carousel, Pagination } from 'react-bootstrap';


 
// const Payment=()=>{
  
//    const columns = [
  
    
//       {
//         name: "Date.",
//        selector: "date",
//         sortable: true,
        

        
//     },
//     {
//       name: "Order Id",
//      selector: "orderid",
//       sortable: true,
       
      
//   },
//   {
//       name: "Subscription/Item",
//      selector: "subscription",
//       sortable: true,
//      className:"texttransformcapitalize"
      
//   },
//   {
//       name: " Payment Id",
//      selector: "paymentid",
//       sortable: true,

      
//   },
//   {
//       name: "Amount",
//      selector: "amount",
//       sortable: true,
//       className:"texttransformcapitalize"

      
//   },
//   {
//       name: "Status",
//      selector: "status",
//       sortable: true,
//       className:"texttransformcapitalize",
//       cell: (d) => [
     
//         d.status=="Success"? <span className="" style={{color:'#04DE71'}}>Success</span> : <span className="" style={{color:'#FF3B30'}}>Fail</span>  
        
//       ]
      
//   }
//   ,{
//       name: " Receipt",
//      selector: "receipt",
//       sortable: true,
//       cell: (d) => (
//         <div className="dlink">
//             <AiOutlineFilePdf size='1.9em' color='#8B0000'/>
//          </div>
//        ),

//   }
    
//     ];
   

//     const data = [
//         {
//           date: "10 Nov 2022",
//           orderid: "10001",
//           subscription: "Paid Directory",
//           paymentid: "1234",
//           amount: "Rs.500",
//           status: "Success",
//           receipt: "Active",


            
            
//           },
//           {
//             date: "10 Nov 2022",
//             orderid: "10001",
//             subscription: "Matrimony",
//             paymentid: "1234",
//             amount: "Rs.500",
//             status: "Success",
//             receipt: "Active",
  
  
              
              
//             },
//             {
//                 date: "10 Nov 2022",
//                 orderid: "10001",
//                 subscription: "Business Directory",
//                 paymentid: "1234",
//                 amount: "Rs.500",
//                 status: "Success",
//                 receipt: "Active",
      
      
                  
                  
//                 },
//                 {
//                     date: "10 Nov 2022",
//                     orderid: "10001",
//                     subscription: "Sponsered Ads",
//                     paymentid: "1234",
//                     amount: "Rs.500",
//                     status: "Fail",
//                     receipt: "Active",
          
          
                      
                      
//                     },
//       ]
    


   
    


    

//     const tableData = {
//       columns,
//       data,
      
//     };

//     // useEffect(() => {
//     //   loadUsers();
//     // },[]);
  
//     // const loadUsers = async () => {
//     //   const result = await axios.get("/patient/getAllPatients");

//     //   setData(result.data.data);
//     //   console.log("patientlist",result.data.data)


//     // };

    
  
  
  
   

//   return (
//     <div >
// <DataTableExtensions {...tableData}>
//         <DataTable
//           columns={columns}
//           data={data}
//           noDataComponent= "Data is Not Available"          striped
//           noHeader
//           defaultSortField="id"
//           defaultSortAsc={true}
//           pagination
//           highlightOnHover
//           print={false}
//           export={true}
        
//         />
//       </DataTableExtensions>
//     </div>

//            );
// }
 
const Payment =()=>{
  
  
  const [data, setData] = useState([]);

const loadUsers = async () => {
    // const response = await axios.get('/employeeDetails')
    //  .catch((err) => {
    //      alert("Getting error in featching data")
    //  });
    //  console.log("response",response?.data)
    //  setData(response?.data)
 }


 const [showRecord, setshowRecord] = useState(10);

 const  handleChanegeShowRec = (e) => {
   debugger;
   const { name, value, type, checked } = e.currentTarget;

   setshowRecord( e.currentTarget.value);
  }
 //for table records per page
 const [recordCount, setrecordCount] = useState(10);


 //for pagination

 const [pageData, setPageData] = useState([]);
const [page, setPage] = useState(1);
const [pageCount, setPageCount] = useState(10);


// handle next
const handleNext = () => {
   if (page === pageCount) return page;
   setPage(page + 1)
}

// handle previous
const handlePrevios = () => {
   if (page === 1) return page;
   setPage(page - 1)
}

useEffect(() => {
 loadUsers()
}, [page])

useEffect(() => {
   const pagedatacount = Math.ceil(data.length / showRecord);
   //setPageCount(pagedatacount);

   if (page) {
       const LIMIT = showRecord;
       const skip = LIMIT * page // 5 *2 = 10
       const dataskip = data.slice(page === 1 ? 0 : skip - LIMIT, skip);
       setPageData(dataskip)
   }
}, [data])

  return(
    <div>
<div className='row ' >
            <div className='col-2' style={{float:'left'}}>
        <select  id="inputState" name='showrecc' onChange={handleChanegeShowRec}
                  className="selectdrpdn form-control" style={{height:"36px",marginTop:"4px"}}>
                  <option  value="10">Show 10</option>
                  <option value="25">Show 25</option>
                  <option value="50">Show 50</option>
                  <option value="100">Show 100</option>

                  </select>
                  </div>
                  <div className='col-8'></div>
                  <div className='form-group col-2'  style={{float:'right'}}>
                  <input
className="form-control"
placeholder="Search..."
type="search"
style={{height:"36px",marginTop:"4px",fontSize:"12px"}}
/>
                  </div>

        </div>
<table class="table  table-hove">
  <thead>
    <tr>
      <th scope="col" className="tableroww">Sr.No</th>
      <th scope="col" className="tableroww">Date</th>
      <th scope="col" className="tableroww">Order Id</th>
      <th scope="col" className="tableroww">Subscription/Item</th>
      <th scope="col" className="tableroww">Payment Id</th>
      <th scope="col" className="tableroww">Amount</th>
      <th scope="col" className="tableroww">Status</th>
      <th scope="col" className="tableroww">Receipt</th>


    </tr>
  </thead>
  <tbody>
   
    {/* {
        data.map((data,index)=>( */}
            <tr>
            <th  className="tableroww1">1</th>
            <td className="tableroww1">10 Nov 2022</td>
            <td className="tableroww1">10001</td>
            <td className="tableroww1">Sponsered Ads</td>
            <td className="tableroww1">1234</td>
            <td className="tableroww1">Rs.500</td>

            <td className="tableroww1">
              {/* {data.Status} */}Fail
              {/* {data.status=="Success"? <span className="" style={{color:'#04DE71'}}>Success</span> : <span className="" style={{color:'#FF3B30'}}>Fail</span> }  */}

              </td>
            <td><div className="dlink"><AiOutlineFilePdf size='1.9em' color='#8B0000'/></div></td>

            {/* <td>
                <Link className='btn btn-primary mr-2'to= {`/View/${data.id}`}>View</Link>
                <Link className='btn btn--outline-primary mr-2' to= {`/EditUser/${data.id}`}>Edit</Link>
                <Link className='btn btn-outline-danger' onClick={() =>deleteUser(data.id)}>Delete</Link>
            </td> */}
            </tr>


        {/* ))
    } */}
  </tbody>
</table>
<div className=""style={{float:"right"}}>
       <Pagination>

<Pagination.Prev onClick={()=>handlePrevios()} disabled={page === 1} />
{
    Array(pageCount).fill(null).map((ele, index) => {
        return (
            <>
                <Pagination.Item active={page === index + 1 ? true : false} onClick={() => setPage(index + 1)}>{index + 1}</Pagination.Item>
            </>
        )
    })
}
<Pagination.Next onClick={()=>handleNext()} disabled={page === pageCount} />
</Pagination>
               </div>
    </div>
  )
}
export default Payment;